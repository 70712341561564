/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { useUserIPGrabber } from '@cleverrealestate/clever-helpers';
import AuthorBio from '@cleverrealestate/clever-components-v2/dist/components/AuthorBio';
import SEO from '../components/meta/SEO';
import Layout from '../components/layout/Layout';

const AuthorBioTemplate = ({ pageContext }) => {
  useUserIPGrabber();
  const {
    pathname,
    meta_title,
    author,
    meta_description,
    contentTopic,
    contentType,
  } = pageContext;

  return (
    <Layout id="page-author-bio">
      <SEO
        pathname={pathname}
        title={meta_title || ''}
        description={meta_description}
        contentTopic={contentTopic}
        contentType={contentType}
      />
      <AuthorBio author={author} header="Homebay" />
    </Layout>
  );
};

AuthorBioTemplate.propTypes = {
  pageContext: PropTypes.any,
};

AuthorBioTemplate.defaultProps = {
  pageContext: {},
};

export default AuthorBioTemplate;
